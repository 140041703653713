var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-6 sm:grid-cols-1 sm:gap-1" },
      [
        _c(
          "div",
          {
            staticClass:
              "col-span-9 flex flex-col justify-center sm:col-span-1",
          },
          [
            _c(
              "div",
              {
                staticClass: "w-full rounded overflow-hidden shadow-lg mb-6",
                attrs: { id: "help-ch-06" },
              },
              [
                _c("div", { staticClass: "px-6 pb-12" }, [
                  _c("ul", { staticClass: "text-base" }, [
                    _c("li", { staticClass: "mb-4" }, [
                      _c(
                        "p",
                        { staticClass: "flex flex-row" },
                        [
                          _c(
                            "span",
                            { staticClass: "text-5xl mx-3 text-yellow-400" },
                            [_vm._v("1.")]
                          ),
                          _c("spam", { staticClass: "text-justify" }, [
                            _vm._v(
                              " こちらは、ご請求いただく際のサンプルの請求書になります。※あくまでサンプルです。"
                            ),
                            _c("br"),
                            _vm._v(
                              " 項目名は「ライセンス費」など内容がわかれば、構いませんが、バイヤー名、作品名、見積もり番号を入れていただきますよう、お願いいたします。"
                            ),
                            _c("br"),
                            _vm._v(
                              " また、許諾料支払いに関しては、一律、支払い手数料がかかります。ただし、オンラインフィルムマーケットのプロフィール欄の「適格請求書発行事業者の確認」に登録していただいたライセンサー様については、この手数料を免除いたします。詳細はこちらのリリースをご確認ください。"
                            ),
                            _c("br"),
                            _vm._v(
                              " 適格請求書に関しては、国税庁のページにてご確認ください。"
                            ),
                            _c("br"),
                            _vm._v(
                              " 非適格請求書の場合でも、消費税に関して、区分記載請求書の記載要件を満たした請求書を発行いただく必要がございますので、ご対応をお願いいたします。"
                            ),
                            _c("br"),
                            _vm._v(
                              " なお、弊社にて請求書の書き方についてのご質問はお受けできません。必要があれば、国税局、税務署、税理士様等にご確認をお願いいたします。"
                            ),
                            _c("br"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._m(1),
                    _vm._m(2),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "help-sumally" }, [
          _c(
            "div",
            { staticClass: "help-index border-2 border-base-yellow mb-8 p-4" },
            [
              _c(
                "h4",
                { staticClass: "font-bold text-xl text-left mt-4 mb-2 ml-4" },
                [_vm._v("他のヘルプを見る")]
              ),
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/" },
                    },
                    [_vm._v(" 主な流れ ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/account" },
                    },
                    [_vm._v(" 新規アカウント登録 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register" },
                    },
                    [_vm._v(" 作品登録(単体登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register-all" },
                    },
                    [_vm._v(" 作品登録(一括登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-list" },
                    },
                    [_vm._v(" 映画一覧の確認と編集 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/quotation" },
                    },
                    [_vm._v(" 見積書作成 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/upload" },
                    },
                    [_vm._v(" 納品アップロード ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/invoice" },
                    },
                    [_vm._v(" 請求書について ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/youtube-limit" },
                    },
                    [_vm._v(" Youtubeでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-register" },
                    },
                    [_vm._v(" Vimeoで動画登録を行いたいです ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-limit" },
                    },
                    [_vm._v(" Vimeoでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/password" },
                    },
                    [_vm._v(" アカウントのパスワードを忘れました ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/email" },
                    },
                    [_vm._v(" 新規アカウント登録完了メールが届きません ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "text-center font-bold text-3xl" }, [
          _vm._v("請求書について"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mb-4" }, [
      _c("p", { staticClass: "flex flex-row" }, [
        _c("span", { staticClass: "text-5xl mx-3" }, [_vm._v("   ")]),
        _c(
          "a",
          {
            staticClass: "text-blue-600",
            attrs: {
              href: "https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/pdf/0020006-027.pdf",
              target: "_blank",
            },
          },
          [
            _vm._v(
              " https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/pdf/0020006-027.pdf "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mb-4" }, [
      _c("img", {
        staticClass:
          "border border-gray-400 shadow-md my-2 w-9/12 mx-auto block",
        attrs: {
          src: require("@/assets/image/help/ch/sample-invoice-v2.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }